// extracted by mini-css-extract-plugin
export var arrowLeft = "carousel-module--arrowLeft--yAspE";
export var arrowRight = "carousel-module--arrowRight--mtjP3";
export var carousel = "carousel-module--carousel--ZrkkM";
export var grid = "carousel-module--grid--L9LK1";
export var image = "carousel-module--image--tXXG3";
export var loader = "carousel-module--loader--TWiKr";
export var placeholderSlide = "carousel-module--placeholderSlide--DZBoF";
export var slide = "carousel-module--slide--4DT+r";
export var spinner = "carousel-module--spinner--vRq9h";
export var text = "carousel-module--text--+Fgml";