// extracted by mini-css-extract-plugin
export var border = "list-module--border--tDnc-";
export var content = "list-module--content--9Am78";
export var date = "list-module--date--jYZ6D";
export var description = "list-module--description--d6plT";
export var details = "list-module--details--iqSVe";
export var dropdown = "list-module--dropdown--B-8+W";
export var grid = "list-module--grid--TnCAA";
export var hyperlink = "list-module--hyperlink--pV+bD";
export var icon = "list-module--icon--q6wbG";
export var image = "list-module--image--GJHcp";
export var info = "list-module--info--IqvFV";
export var links = "list-module--links--9vnaC";
export var load = "list-module--load--4KwfQ";
export var loader = "list-module--loader--UNshZ";
export var row = "list-module--row--9etxu";
export var spinner = "list-module--spinner--iY7C4";
export var wrapper = "list-module--wrapper--xyZyf";