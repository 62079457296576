// extracted by mini-css-extract-plugin
export var chat = "chat-module--chat--3UDEj";
export var chatAlert = "chat-module--chatAlert--9L8-s";
export var chatAlertMask = "chat-module--chatAlertMask--325cj";
export var chatbox = "chat-module--chatbox--jfkkI";
export var grid = "chat-module--grid--h+RGZ";
export var header = "chat-module--header--XBLLh";
export var load = "chat-module--load--Jstp7";
export var loader = "chat-module--loader--EqFQn";
export var spinner = "chat-module--spinner--S+1sD";
export var status = "chat-module--status--3+F5j";
export var title = "chat-module--title--VnsAU";