// extracted by mini-css-extract-plugin
export var border = "show-module--border--sCxk8";
export var details = "show-module--details--RAjuV";
export var flex = "show-module--flex--TevcV";
export var grid = "show-module--grid--NjXeq";
export var heading = "show-module--heading--yxXsE";
export var icon = "show-module--icon--fiMGg";
export var image = "show-module--image--i9DgA";
export var info = "show-module--info--348Nb";
export var play = "show-module--play--G1fqT";
export var player = "show-module--player--dU7kt";
export var row = "show-module--row--EDMk2";
export var tracklist = "show-module--tracklist--QZLaE";